<template>
  <div>
    <v-row align="start">
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Tipo de negocio</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="businessTypeSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.business_type)"
                >
                  Crear Tipo
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.business_type"
              :items="business_type_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :search="businessTypeSearch"
              :loading="loadingBusinessTypeTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.business_type)">
                  mdi-pencil
                </v-icon>
                <!-- <v-icon color="primary-red" small @click="openDelete(item, boards.business_type)">
                  mdi-delete
                </v-icon> -->
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Géneros</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="genderSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.gender)"
                >
                  Crear Cliente
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.gender"
              :items="gender_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :search="genderSearch"
              :loading="loadingGenderTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.gender)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.gender)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="start">
      <v-col sm="12" md="6">
        <v-card class="rounded-xl">
          <v-card-title class="py-2">
            <v-row align="center" justify="space-around">
              <v-col xs="12" sm="12" md="3" >
                <h5>Nacionalidad</h5>
              </v-col>
              <v-col xs="12" sm="12" md="5" >
                <v-text-field
                  class="pt-0"
                  v-model="nationalitySearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                ></v-text-field>    
              </v-col>
              <v-col xs="12" sm="12" md="4" class="d-flex justify-center">
                <v-btn
                  dense
                  small
                  color="primary"
                  @click="openCreateForm(boards.nationality)"
                >
                  Crear Nacionalidad
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="pa-0 ma-0">
            <v-data-table          
              dense
              :headers="headers.nationality"
              :items="nationality_records"
              :items-per-page="5"
              multi-sort
              item-key="name"
              class=" rounded-xl"
              :search="nationalitySearch"
              :loading="loadingNationalityTable"
              loading-text="Loading... Please wait"
            >

              <template v-slot:item.actions="{ item }">
                <v-icon color="warning" small class="mr-2" @click="editItem(item, boards.nationality)">
                  mdi-pencil
                </v-icon>
                <v-icon color="primary-red" small @click="openDelete(item, boards.nationality)">
                  mdi-delete
                </v-icon>
              </template>
            
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>

    <!-- Businnes type Form -->
    <v-row justify="center" >
      <v-form ref="createBusinessTypeForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogBusinessType" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Tipo de negocio ${createBusinessTypeForm.name}` : `Crear Tipo de negocio` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="6">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createBusinessTypeForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="6">
                    <v-text-field
                      dense
                      label="Código"
                      v-model="createBusinessTypeForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.business_type)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.business_type)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.business_type)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Gender Form -->
    <v-row justify="center" >
      <v-form ref="createGenderForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogGender" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Género ${createGenderForm.name}` : `Crear Género` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="6">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createGenderForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="6">
                    <v-text-field
                      dense
                      label="Código"
                      v-model="createGenderForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.gender)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.gender)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.gender)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- Nationality Form -->
    <v-row justify="center" >
      <v-form ref="createNationalityForm" v-model="valid" lazy-validation >
        <v-dialog  v-model="dialogNationality" persistent max-width="600px" >
          <v-card :loading="isLoading" :disabled="isLoading" class="rounded-xl" >
            <v-card-title>
              <span class="text-h6">{{ btnEdithVisible ? `Edicion Nacionalidad ${createNationalityForm.name}` : `Crear Nacionalidad` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-container>
                <v-row dense>
                  <v-col xs="12" md="6">
                    <v-text-field
                      dense
                      label="Nombre"
                      v-model="createNationalityForm.name"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col xs="12" md="6">
                    <v-text-field
                      dense
                      label="Código"
                      v-model="createNationalityForm.code"
                      :rules="createFormRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
                :disable="isLoading"
                color="blue darken-1" text @click="closeForm(boards.nationality)">
                Cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="!btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('POST', boards.nationality)"
              >
                Guardar y cerrar
              </v-btn>
              <v-btn
                :loading="isLoading"
                v-if="btnEdithVisible"
                color="blue darken-1"
                text
                @click="summitForm('PUT', boards.nationality)"
              >
                Guardar Edicion
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

    <!-- DIALOG DELETE BUSINESS TYPE -->
    <v-dialog v-model="dialogDeleteBusinessType" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este tipo de negocio?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.business_type)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.business_type)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE GENDER -->
    <v-dialog v-model="dialogDeleteGender" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar este género?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.gender)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.gender)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE NATIONALITY -->
    <v-dialog v-model="dialogDeleteNationality" max-width="500px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title class="text-h5">¿Está seguro de borrar esta nacionalidad?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="blue darken-1" text @click="closeDelete(boards.nationality)">Cancel</v-btn>
          <v-btn small color="blue darken-1" text @click="deleteItemConfirm(boards.nationality)">OK</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> 
</template>

<script>
import {axiosN506} from '../api/axiosBd'
export default {
  props:{
    headers: {
      type: Object,
      required: true
    },
    boards: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      //loadings
      isLoading: false,
      loadingBusinessTypeTable: false,
      loadingGenderTable: false,
      loadingNationalityTable: false,
      //dialog delete
      dialogDeleteBusinessType: false,
      dialogDeleteGender: false,
      dialogDeleteNationality: false,
      //search
      businessTypeSearch: '',
      genderSearch: '',
      nationalitySearch: '',
      //records
      business_type_records: [],
      gender_records: [],
      nationality_records: [],
      //form
      btnEdithVisible: false,
      valid: false,
      dialogBusinessType: false,
      dialogGender: false,
      dialogNationality: false,
      createBusinessTypeForm: {},
      createGenderForm: {},
      createNationalityForm: {},
      //form rules
      createFormRules: [
        v => !!v || 'El Campo es requerido',
      ],
      selectedRecord: null
    }
  },
  computed: {
  },
  methods: {
    async summitForm(method, board){
      this.isLoading = true
      let error = false
      let form = {}

      if (board == this.boards.business_type) {
        if (!this.$refs.createBusinessTypeForm.validate()) {
          error = true
        } else {
          form = this.createBusinessTypeForm
        }
      }

      if (board == this.boards.gender) {
        if (!this.$refs.createGenderForm.validate()) {
          error = true
        } else {
          form = this.createGenderForm
        }
      }

       if (board == this.boards.nationality) {
        if (!this.$refs.createNationalityForm.validate()) {
          error = true
        } else {
          form = this.createNationalityForm
        }
      }

      if (!error) {
        await this.$store.dispatch('verifyToken')

        if (method == 'POST') {
          axiosN506(`${board}/add`, {
            method: "POST",
            data: form,
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        } else if (method == 'PUT') {
          axiosN506(`${board}/update`, {
            method: "PUT",
            data: { id: this.selectedRecord, ...form },
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then(() => {
            this.isLoading = false
            this.getData(board)
            this.closeForm(board)
          }).catch((err) => {
            alert(`error: ${err.response ? err.response : err}`)
            this.isLoading = false          
          })
        }
      } else {
        //construir componente o hacer prototype para mensajes de error standar en la aplicacion
        this.$alert('Favor llenar todos los campos requeridos', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.isLoading = false
          }
        })
      }
      
      // this.isLoading = true
    },
    editItem(item, board){
      // this.loa
      this.btnEdithVisible = true
      this.selectedRecord = item.id
      
      if (board == this.boards.business_type) {
        this.createBusinessTypeForm.name = item.name
        this.createBusinessTypeForm.code = item.code
        this.dialogBusinessType = true
      }

      if (board == this.boards.gender) {
        this.createGenderForm.name = item.name
        this.createGenderForm.code = item.code
        this.dialogGender = true
      }

      if (board == this.boards.nationality) {
        this.createNationalityForm.name = item.name
        this.createNationalityForm.code = item.code
        this.dialogNationality = true
      }
    },
    deleteItemConfirm(board) {
      this.isLoading = true

      axiosN506(`${board}/delete`, {
        method: "DELETE",
        data: { id: this.selectedRecord },
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then(() => {
        this.isLoading = false
        this.getData(board)
        this.closeDelete(board)
      }).catch((err) => {
        console.error(err)
        alert(`error: ${err.response ? err.response : err}`)
        this.isLoading = false          
      })
    },
    openDelete(item, board) {
      if (board == this.boards.business_type) this.dialogDeleteBusinessType = true
      if (board == this.boards.gender) this.dialogDeleteGender = true
      if (board == this.boards.nationality) this.dialogDeleteNationality = true

      this.selectedRecord = item.id
    },
    openCreateForm(board) {
      if (board == this.boards.business_type) this.dialogBusinessType = true
      if (board == this.boards.gender) this.dialogGender = true
      if (board == this.boards.nationality) this.dialogNationality = true
    },
    closeForm(board) {
      if (board == this.boards.business_type) {
        this.dialogBusinessType = false
        this.$refs.createBusinessTypeForm.resetValidation()
        this.createBusinessTypeForm = {}
      }

      if (board == this.boards.gender) {
        this.dialogGender = false
        this.$refs.createGenderForm.resetValidation()
        this.createGenderForm = {}
      }

      if (board == this.boards.nationality) {
        this.dialogNationality = false
        this.$refs.createNationalityForm.resetValidation()
        this.createNationalityForm = {}
      }

      this.btnEdithVisible = false
      this.isLoading = false
      this.selectedRecord = null
    },
    closeDelete(board) {
      this.selectedRecord = null

      if (board == this.boards.business_type) this.dialogDeleteBusinessType = false
      if (board == this.boards.gender) this.dialogDeleteGender = false
      if (board == this.boards.nationality) this.dialogDeleteNationality = false
    },
    getData(board){
      this.loadingTable = true
      this.$store.dispatch('verifyToken')
      axiosN506({
        method: 'GET',
        url: `${board}/list`,
        headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
      })
      .then((result) => {
        if (board == this.boards.business_type) this.loadingBusinessTypeTable = false
        if (board == this.boards.gender) this.loadingGenderTable = false
        if (board == this.boards.nationality) this.loadingNationalityTable = false
        
        if (board == this.boards.business_type) this.business_type_records = result.data.data
        if (board == this.boards.gender) this.gender_records = result.data.data
        if (board == this.boards.nationality) this.nationality_records = result.data.data
        console.log(this.business_type_records)
      }).catch((err) => {
        console.error(err)
      });
    },
    convertToMoney(x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  created() {
    this.getData(this.boards.business_type)
    this.getData(this.boards.gender)
    this.getData(this.boards.nationality)
  }
}
</script>